.form-radio-standard {
    margin-bottom: rem(15px);

    &__control {
        position: relative;
    }

    &__input {
        position: absolute;
        left: 0;
        z-index: -1;
        opacity: 0;

        &:checked ~ .form-radio-standard__label {
            &::after {
                opacity: 1;
            }
        }
    }

    &__label {
        padding-left: rem(35px);
        font-size: rem(15px);
        font-weight: 500;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: rem(24px);
            height: rem(24px);
            background: #fff;
            border: #979797 1px solid;
            border-radius: 100%;
        }

        &::after {
            content: "";
            position: absolute;
            left: rem(6px);
            top: rem(6px);
            width: rem(12px);
            height: rem(12px);
            background: theme-color("primary");
            border-radius: 100%;
            opacity: 0;
        }
    }
}

.no-form-margin-mobile .form-group {
    @include media-breakpoint-down(md) {
        margin-bottom: 0;
    }
}

.no-form-margin-desktop .form-group {
    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
    }
}
.form-radio {
    position: relative;
    margin-bottom: rem(40px);

    &__control {
        position: relative;
    }

    &__input {
        position: absolute;
        left: 0;
        z-index: -1;
        opacity: 0;

        &:checked~.form-radio__label .text{
            border-color: theme-color("secondary");
            box-shadow: 0 0 18px 0 rgba(#000, 0.1);

            &::after {
                opacity: 1;
            }
        }
    }

    &__label {
        display: block;
        margin: 0;
        text-align: center;
        cursor: pointer;

        .image {
            display: none;
        }

        .text {
            position: relative;
            display: inline-block;
            width: 100%;
            padding: rem(20px) rem(15px);
            font-size: rem(13px);
            font-weight: 500;
            letter-spacing: rem(0.4px);
            background: #fff;
            border: #E9E9E9 1px solid;
            border-radius: rem(10px);
            transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out;

            &::after {
                content: "";
                position: absolute;
                right: 0;
                top: 0;
                z-index: 20;
                display: block;
                width: rem(28px);
                height: rem(28px);
                background: #fff escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='11' height='9' fill='#{$secondary}' viewBox='0 0 11 9'><path d='M5.57223841,-0.185725394 C5.80682689,-0.439128528 6.18632156,-0.478195906 6.46513115,-0.293458685 L6.55400172,-0.223577563 L10.2375087,3.18643669 C10.4401487,3.37403107 10.4523456,3.69037852 10.2647512,3.8930185 C10.0980007,4.07314292 9.82951928,4.10279378 9.62959486,3.97540557 L9.55816941,3.92026097 L6.117,0.735 L0.392923508,8.00921047 C0.241125972,8.20210439 -0.0241205426,8.25315057 -0.233600181,8.14217396 L-0.309210473,8.09292351 C-0.502104394,7.94112597 -0.553150565,7.67587946 -0.442173958,7.46639982 L-0.392923508,7.39078953 L5.5360968,-0.143403725 C5.55360232,-0.165047297 5.55360232,-0.165047297 5.57223841,-0.185725394 Z' transform='translate(4.948910, 3.895760) rotate(-180.000000) translate(-4.948910, -3.895760) '></path></svg>")) center center no-repeat;
                border: theme-color("secondary") 1px solid;
                border-radius: 100%;
                color: theme-color("secondary");
                opacity: 0;
                transform: translate(50%, -50%);
                transition: opacity 0.3s ease-in-out;
            }
        }

        &:hover {
            .text {
                box-shadow: 0 0 18px 0 rgba(#000, 0.1);
            }
        }
    }

    &--image {
        margin-bottom: rem(20px);

        .form-radio{
            &__label {
                .image {
                    display: block;
                    width: 45%;
                    overflow: hidden;
                    border-radius: rem(10px);

                    .img-fluid {
                        width: 100%;
                        max-width: inherit;
                    }
                }

                .text {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    width: 50%;
                    padding: rem(15px);
                    transform: translateY(-50%);
                }
            }
        }
    }

    &__children {
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 30;
        width: 100%;
        padding: rem(15px) rem(10px) 0 rem(10px);
        background: #fff;
        border: theme-color("secondary") 1px solid;
        border-radius: rem(10px);
        transform: translate(-50%, -50%);

        .title {
            display: block;
            margin-bottom: rem(20px);
            font-size: rem(15px);
            font-weight: 500;
            letter-spacing: rem(0.5px);
            text-align: center;
            color: theme-color("secondary");
        }

        .description {
            margin-bottom: rem(15px);
            font-size: rem(13px);
            letter-spacing: rem(0.5px);
            text-align: center;
        }

        .form-radio {
            padding: rem(10px);
            margin: 0 rem(-10px) !important;
            border-top: #E9E9E9 1px solid;

            &__input {
                &:checked~.form-radio__label{
                    &::after{
                        display: block;
                    }

                    .text {
                        box-shadow: none;
                    }
                }
            }

            &__label {
                padding-left: rem(30px);
                text-align: left;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    width: 20px;
                    height: 20px;
                    border: #979797 1px solid;
                    border-radius: 100%;
                    transform: translateY(-50%);
                }

                &::after {
                    content: "";
                    position: absolute;
                    left: 5px;
                    top: 50%;
                    display: none;
                    width: 10px;
                    height: 10px;
                    background: theme-color("primary");
                    border-radius: 100%;
                    transform: translateY(-50%);
                }

                .image {
                    display: none;
                }

                .text {
                    position: static;
                    vertical-align: middle;
                    width: auto;
                    padding: 0;
                    font-size: rem(15px);
                    border: none;
                    border-radius: 0;
                    transform: translateY(0) !important;

                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .form-radio {
        margin-bottom: 0;

        &__input {
            &:checked~.form-radio__label .image{
                &::after {
                    opacity: 1;
                }
            }
        }

        &__label {
            position: relative;
        }

        &--image {
            .form-radio {
                &__label {
                    .image {
                        width: auto;

                        &::after {
                            content: "";
                            position: absolute;
                            right: 0;
                            top: 0;
                            z-index: 20;
                            display: block;
                            width: rem(28px);
                            height: rem(28px);
                            background: #fff escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='11' height='9' fill='#{$secondary}' viewBox='0 0 11 9'><path d='M5.57223841,-0.185725394 C5.80682689,-0.439128528 6.18632156,-0.478195906 6.46513115,-0.293458685 L6.55400172,-0.223577563 L10.2375087,3.18643669 C10.4401487,3.37403107 10.4523456,3.69037852 10.2647512,3.8930185 C10.0980007,4.07314292 9.82951928,4.10279378 9.62959486,3.97540557 L9.55816941,3.92026097 L6.117,0.735 L0.392923508,8.00921047 C0.241125972,8.20210439 -0.0241205426,8.25315057 -0.233600181,8.14217396 L-0.309210473,8.09292351 C-0.502104394,7.94112597 -0.553150565,7.67587946 -0.442173958,7.46639982 L-0.392923508,7.39078953 L5.5360968,-0.143403725 C5.55360232,-0.165047297 5.55360232,-0.165047297 5.57223841,-0.185725394 Z' transform='translate(4.948910, 3.895760) rotate(-180.000000) translate(-4.948910, -3.895760) '></path></svg>")) center center no-repeat;
                            border: theme-color("secondary") 1px solid;
                            border-radius: 100%;
                            color: theme-color("secondary");
                            opacity: 0;
                            transform: translate(50%, -50%);
                            transition: opacity 0.3s ease-in-out;
                        }
                    }

                    .text {
                        position: absolute;
                        right: 50%;
                        top: auto;
                        bottom: 0;
                        width: auto;
                        max-width: 70%;
                        transform: translate(50%, 50%);

                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }

        &__children {
            top: auto;
            bottom: 0;
            //width: 50%;
            padding-top: rem(30px);
            transform: translate(-50%, 50%);

            .form-radio {
                padding-right: rem(30px);
                padding-left: rem(30px);

                &__label {
                    .image {
                        display: none;
                    }

                    .text {
                        position: static;
                        width: auto;
                        max-width: inherit;
                        padding: 0 !important;
                        box-shadow: none;

                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .form-radio {
        &__label {
            .text {
                /*padding: rem(30px) rem(60px);*/
                padding: rem(25px) rem(15px);
                font-size: rem(15px);
                letter-spacing: rem(0.4px);

                &::after {
                    width: rem(56px);
                    height: rem(56px);
                    background-size: 21px 15px;
                }
            }
        }

        &--image {
            .form-radio {
                &__label {
                    .text {
                        /*padding: rem(30px) rem(60px);*/
                        padding: rem(20px) rem(50px);
                    }

                    .image {
                        &::after {
                            width: rem(56px);
                            height: rem(56px);
                            background-size: 21px 15px;
                        }
                    }
                }
            }
        }
    }
}

.no-form-margin-mobile .form-radio {
    @include media-breakpoint-down(md) {
        margin-bottom: 0;
    }
}

.no-form-margin-desktop .form-radio {
    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
    }
}

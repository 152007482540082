.modal {
    text-align: center;

    &-header {
        display: block;

        .close {
            position: absolute;
            right: rem(15px);
            top: rem(15px);
        }
    }

    &-title {
        padding: 0 rem(15px);
        font-size: rem(19px);
        font-weight: 600;
        letter-spacing: rem(0.5px);
        color: theme-color("primary");
    }

    &-body {
        font-size: rem(13px);
        letter-spacing: rem(0.4px);
        color: #64666E;
    }
}

@include media-breakpoint-up(lg) {
    .modal {
        &-title {
            padding: rem(15px) rem(30px);
            font-size: rem(25px);
        }

        &-body {
            padding: rem(15px) rem(30px);
            font-size: rem(15px);
        }
    }
}

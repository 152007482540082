.draggable-logo {
    position: relative;
    margin-bottom: rem(45px);

    &__area {
        position: absolute;
        left: 6%;
        top: 6.93%;
        z-index: 20;
        width: 85.11%;
        height: 49.13%;

        .react-draggable {
            img {
                display: block;
                width: 100%;
                height: 100%;
            }

            span {
                div{
                    &:nth-child(1),
                    &:nth-child(3) {
                        &::before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 50%;
                            width: 100%;
                            height: 1px;
                            background: #fff;
                            box-shadow: rgba(#000, 0.5) 0 0 1px 0;
                            transform: translateY(-50%);
                        }
                    }

                    &:nth-child(2),
                    &:nth-child(4) {
                        &::before {
                            content: "";
                            position: absolute;
                            left: 50%;
                            top: 0;
                            width: 1px;
                            height: 100%;
                            background: #fff;
                            box-shadow: rgba(#000, 0.5) 0 0 1px 0;
                            transform: translateX(-50%);
                        }
                    }

                    &:nth-child(5), 
                    &:nth-child(6), 
                    &:nth-child(7), 
                    &:nth-child(8) {
                        &::before {
                            content: "";
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            width: rem(8px);
                            height: rem(8px);
                            background: #fff;
                            border: #000 1px solid;
                            transform: translate(-50%, -50%);
                        }
                    }
                }
            }
        }
    }

    &__background {
        padding-top: (346px / 1175px) * 100%; // ($height / $width) * 100%

        .img-fluid {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
}

@include media-breakpoint-up(lg) {
    .draggable-logo {
        margin-bottom: rem(90px);
    }
}

.scrollable-options {
    width: calc(100% + #{$grid-gutter-width});
    padding-top: rem(30px);
    padding-bottom: rem(30px);
    margin-right: rem(-$grid-gutter-width/2);
    margin-left: rem(-$grid-gutter-width/2);
    overflow-x: auto;
    overflow-y: initial;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;

    .form-radio {
        display: inline-block;
        width: 60vw;
        padding-right: rem(20px);
        padding-left: rem(20px);
        text-align: left;
        white-space: initial;
    }

    .inline {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        .form-radio {
            -ms-flex-preferred-size: 0;
            flex-basis: 0;
            -ms-flex-positive: 1;
            flex-grow: 1;
            max-width: 100%;
            border-top: none;
        }
    }
}

@include media-breakpoint-up(md) {
    .scrollable-options {
        .form-radio {
            width: 30vw;
        }
    }
}

@include media-breakpoint-down(lg) {
    .scrollable-options {
        &::-webkit-scrollbar {
            width: 0px;
            height: 0px;
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: transparent;
        }
    }
}

@include media-breakpoint-up(lg) {
    .scrollable-options {
        padding-top: rem(45px);
        padding-bottom: rem(100px);
        /*margin-bottom: rem(45px);*/
        margin-bottom: rem(7px);

        .form-radio {
            width: rem(280px);
        }
    }
}

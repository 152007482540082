.mobile-warning {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    display: none;
    width: 100%;
    height: 100%;
    overflow: auto;
    text-align: center;
    background: #fff;

    .container-fluid {
        height: 100%;

        > .row {
            height: 100%;

            > div {
                padding-top: rem(30px);
                padding-bottom: rem(30px);
            }
        }
    }

    .svg-icon {
        margin-bottom: rem(45px);

        &--rotate-device {
            max-width: 75%;
        }
    }

    span {
        display: block;
        font-size: rem(18px);
        font-weight: 500;
        letter-spacing: rem(0.8px);
        color: theme-color("primary");
    }

    &--revert {
        .svg-icon {
            &--rotate-device {
                max-width: 25%;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    @media (orientation: portrait) {
        .mobile-warning {
            display: block;

            &--revert {
                display: none;
            }
        }
    }

    @media (orientation: landscape) {
        .mobile-warning {
            display: none;

            &--revert {
                display: block;
            }
        }
    }
}

.header-progress-bar {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    width: 0;
    height: 100%;
    overflow: hidden;
    background: theme-color("primary");
    color: #fff;
    transition: width 0.3s ease-in-out;

    .container-fluid {
        width: 100vw;
    }

    .svg-icon{
        &--logo-symbol {
            color: #fff !important;
        }
    }

    .title {
        color: #fff;
    }

    .navigation {
        &__toggle {
            color: #fff;

            &:hover {
                color: #000;
            }
        }
    }
}

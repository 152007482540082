.thankyou {
    &__header {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        padding: rem(35px) 0 rem(70px) 0;
        color: theme-color("primary");

        .svg-icon{
            &--logo {
                width: rem(115px);
            }
        }
    }

    &__content {
        height: 100vh;

        > .row {
            height: 100%;
        }

        .title {
            font-size: rem(25px);
            font-weight: 600;
            color: theme-color("primary");
        }
    }
}

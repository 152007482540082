.form-radio-color {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: rem(42px);
    height: rem(42px);
    margin: 0 rem(15px) rem(20px) rem(15px);
    font-size: 0;

    &__input {
        position: absolute;
        left: 0;
        z-index: -1;
        opacity: 0;

        &:checked~.form-radio-color__label {
            > span {
                border-width: 5px;
                opacity: 1;
            }
        }
    }

    &__label {
        display: block;
        width: 100%;
        height: 100%;
        background: red;
        border: red 1px solid;
        border-radius: 100%;
        cursor: pointer;

        > span {
            display: block;
            width: 100%;
            height: 100%;
            background: transparent escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='11' height='9' fill='#ffffff' viewBox='0 0 11 9'><path d='M5.57223841,-0.185725394 C5.80682689,-0.439128528 6.18632156,-0.478195906 6.46513115,-0.293458685 L6.55400172,-0.223577563 L10.2375087,3.18643669 C10.4401487,3.37403107 10.4523456,3.69037852 10.2647512,3.8930185 C10.0980007,4.07314292 9.82951928,4.10279378 9.62959486,3.97540557 L9.55816941,3.92026097 L6.117,0.735 L0.392923508,8.00921047 C0.241125972,8.20210439 -0.0241205426,8.25315057 -0.233600181,8.14217396 L-0.309210473,8.09292351 C-0.502104394,7.94112597 -0.553150565,7.67587946 -0.442173958,7.46639982 L-0.392923508,7.39078953 L5.5360968,-0.143403725 C5.55360232,-0.165047297 5.55360232,-0.165047297 5.57223841,-0.185725394 Z' transform='translate(4.948910, 3.895760) rotate(-180.000000) translate(-4.948910, -3.895760) '></path></svg>")) center center no-repeat;
            border: #fff 0 solid;
            border-radius: 100%;
            opacity: 0;
            transition: opacity 0.3s ease-in-out, border 0.3s ease-in-out;
        }
    }

    &--ffffff {
        .form-radio-color__label {
            border-color: #979797 !important;
            
            > span {
                background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='11' height='9' fill='#979797' viewBox='0 0 11 9'><path d='M5.57223841,-0.185725394 C5.80682689,-0.439128528 6.18632156,-0.478195906 6.46513115,-0.293458685 L6.55400172,-0.223577563 L10.2375087,3.18643669 C10.4401487,3.37403107 10.4523456,3.69037852 10.2647512,3.8930185 C10.0980007,4.07314292 9.82951928,4.10279378 9.62959486,3.97540557 L9.55816941,3.92026097 L6.117,0.735 L0.392923508,8.00921047 C0.241125972,8.20210439 -0.0241205426,8.25315057 -0.233600181,8.14217396 L-0.309210473,8.09292351 C-0.502104394,7.94112597 -0.553150565,7.67587946 -0.442173958,7.46639982 L-0.392923508,7.39078953 L5.5360968,-0.143403725 C5.55360232,-0.165047297 5.55360232,-0.165047297 5.57223841,-0.185725394 Z' transform='translate(4.948910, 3.895760) rotate(-180.000000) translate(-4.948910, -3.895760) '></path></svg>"));
                // border-color: #979797;
            }
        }
    }
}

.no-form-margin-mobile .form-radio-color {
    @include media-breakpoint-down(md) {
        margin-bottom: 0;
    }
}

.no-form-margin-desktop .form-radio-color {
    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
    }
}

.logo-controls {
    text-align: center;

    .btn-link {
        margin-right: rem(15px);
        margin-left: rem(15px);
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

@include media-breakpoint-up(lg) {
    .logo-controls {
        .btn-link {
            margin-right: rem(30px);
            margin-left: rem(30px);
        }
    }
}

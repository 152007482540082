.navigation-nav {
    @include list-unstyled;

    margin: 0;
    font-size: rem(13px);

    &__item {
        position: relative;
        padding-left: rem(20px);
        margin-top: rem(20px);

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: rem(7px);
            display: block;
            width: rem(12px);
            height: rem(8px);
            background: transparent escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='11' height='9' fill='currentColor' viewBox='0 0 11 9'><path d='M5.57223841,-0.185725394 C5.80682689,-0.439128528 6.18632156,-0.478195906 6.46513115,-0.293458685 L6.55400172,-0.223577563 L10.2375087,3.18643669 C10.4401487,3.37403107 10.4523456,3.69037852 10.2647512,3.8930185 C10.0980007,4.07314292 9.82951928,4.10279378 9.62959486,3.97540557 L9.55816941,3.92026097 L6.117,0.735 L0.392923508,8.00921047 C0.241125972,8.20210439 -0.0241205426,8.25315057 -0.233600181,8.14217396 L-0.309210473,8.09292351 C-0.502104394,7.94112597 -0.553150565,7.67587946 -0.442173958,7.46639982 L-0.392923508,7.39078953 L5.5360968,-0.143403725 C5.55360232,-0.165047297 5.55360232,-0.165047297 5.57223841,-0.185725394 Z' transform='translate(4.948910, 3.895760) rotate(-180.000000) translate(-4.948910, -3.895760) '></path></svg>")) center center no-repeat;
            background-size: contain;
            color: theme-color("primary");
        }

        &:first-child {
            margin-top: 0;
        }

        a {
            color: $body-color;

            .item-title {
                display: block;
                font-weight: 600;
                color: theme-color("primary");
            }

            .item-subtitle {
                display: block;
                margin-top: rem(5px);
                font-weight: 600;
            }
        }

        strong {
            font-weight: 400;
            text-decoration: underline;
            color: theme-color("primary");
        }

        &--title {
            font-size: rem(16px);
            font-weight: 700;
            color: theme-color("secondary");

            &::before {
                display: none;
            }
        }
    }
}

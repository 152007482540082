.front-page {
    &__image {
        position: relative;
        margin-right: -$grid-gutter-width/2;
        margin-bottom: rem(30px);
        margin-left: -$grid-gutter-width/2;

        a {
            position: absolute;
            left: 4.16667%;
            top: 40px;
            font-size: rem(11px);
            font-weight: 500;
            letter-spacing: rem(1.5px);
            text-transform: uppercase;
            color: #fff;
        }

        .img-fluid {
            width: 100%;
            max-width: inherit;
            height: 25vh;
            object-fit: cover;
            object-position: 50% 50%;
        }
    }

    &__content {
        margin-bottom: rem(45px);

        .svg-icon {
            &--logo {
                width: rem(108px);
                margin-bottom: rem(15px);
                color: theme-color("primary");
            }
        }

        .title {
            margin-bottom: rem(30px);
            font-size: rem(20);
            font-weight: 300;
            letter-spacing: rem(0.7px);
            line-height: 1.5;

            strong {
                font-weight: 400;
            }
        }

        .btn-link {
            text-decoration: underline;
            color: theme-color("primary");
        }
    }

    &__copyright {
        padding-bottom: rem(30px);
        font-size: rem(10px);
        letter-spacing: rem(0.7px);
        color: rgba($body-color, 0.8);
    }

    &__legal {
        padding-bottom: rem(30px);
        font-size: rem(10px);
        letter-spacing: rem(0.7px);
        
        a {
            display: inline-block;
            margin-left: rem(15px);
            text-decoration: underline;
            color: rgba($body-color, 0.8);

            &:hover {
                text-decoration: none;
            }

            &:first-child {
                margin-left: 0;
            }
        }
    }

    &__form {
        .form-input {
            &__label {
                position: static;
                padding: 0;
                margin: 0;
            }
            
            &__control {
                height: rem(48px);
                padding: rem(12px) rem(15px);
                font-size: rem(15px);
                border-radius: rem(4px);
            }

            &--filled {
                &::after {
                    display: none;
                }
            }
        }

        .form-group {
            margin-bottom: rem(25px);

            &.required {
                label {
                    &::after {
                        content: " *";
                    }
                }
            }
        }

        .btn {
            margin-top: rem(20px);
        }

        &.displayErrors {
            .form-control {
                &:invalid {
                    border-color: theme-color("danger");
                }
            }
        }
    }

    &__login-heading {
        margin-bottom: rem(30px);

        h1 {
            font-size: rem(22px);
            color: theme-color("primary");
        }

        p {
            font-size: rem(15px);
            color: #64666E;
        }
    }
}

.forgottenpassword {
    font-size: rem(14px);
}

@include media-breakpoint-up(lg) {
    .front-page {
        &__image {
            margin-bottom: 0;

            .img-fluid {
                height: calc(100vh - 76px);
                object-fit: cover;
                object-position: 50% 50%;
            }
        }

        &__content {
            margin-bottom: 0;

            .svg-icon {
                &--logo {
                    width: rem(173px);
                    margin-bottom: rem(45px);
                }
            }

            .title {
                margin-bottom: rem(45px);
            }
        }

        .container-fluid > .row:first-child {
            border-bottom: #F0F0F0 1px solid;
        }

        &__copyright,
        &__legal {
            padding-top: rem(30px);
        }
    }
}

@include media-breakpoint-up(xl) {
    .front-page {
        &__content {
            .title {
                font-size: rem(30px);
                margin-bottom: rem(150px);
            }
        }
    }
}

.btn {
    letter-spacing: 1px;

    &.btn-uppercase {
        text-transform: uppercase;
    }

    &.btn-icon {
        position: relative;

        &__prepended,
        &__appended {
            .icon {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);

                .svg-icon {
                    width: rem(18px);
                }
            }
        }

        &__prepended {
            padding-left: rem(78px);

            .icon {
                left: rem(40px);
            }
        }

        &__appended {
            padding-right: rem(78px);

            .icon {
                right: rem(40px);
            }
        }
    }

    &.btn-link {
        color: #C0C0C0;

        &.btn-icon__prepended {
            padding-left: rem(30px);

            .icon {
                left: 0;
            }
        }

        &__appended {
            padding-right: rem(30px);

            .icon {
                right: 0;
            }
        }

        &.btn-underlined {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &.btn-primary {
        &:disabled {
            background-color: #C0C0C0;
            border-color: #C0C0C0;
            cursor: default;
        }
    }
}

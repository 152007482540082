.offline-warning {
    position: absolute;
    left: 0;
    top: rem(61px);
    z-index: 9999;
    width: 100%;
    padding: rem(10px) 0;
    font-size: rem(10px);
    text-align: center;
    background-color: #fff3cd;
    color: #856404;
}

@include media-breakpoint-up(md) {
    .offline-warning {
        font-size: rem(12px);
    }
}

@include media-breakpoint-up(lg) {
    .offline-warning {
        left: 50%;
        top: rem(70px);
        width: auto;
        max-width: 100%;
        padding: rem(10px) rem(15px);
        border-radius: rem(15px);
        box-shadow: 0 2px 8px 2px rgba(53,60,79,.1);
        transform: translateX(-50%);
    }
}

.riepilogo-navigation {
    &__toggle {
        padding: 0;
        font-size: rem(14px);
        font-weight: 500;
        background: none;
        border: none;
        color: #fff;

        span,
        .svg-icon {
            display: inline-block;
            vertical-align: middle;
        }

        span {
            margin-right: rem(10px);
        }

        .svg-icon {
            &--list {
                width: rem(23px);
            }
        }
    }

    &__content {
        position: fixed;
        z-index: 1000;
        left: 0;
        top: 0;
        display: none;
        width: 100%;
        height: 100vh;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        text-align: left;
        background: #fff;
        background: radial-gradient(circle, #fff 50%, #f2f2f2 100%);

        &._shown {
            display: block;
        }
    }

    &__header {
        position: relative;
        padding: rem(25px) $grid-gutter-width/2;
        //margin: 0 -#{$grid-gutter-width/2};
        text-align: right;
        border-bottom: #EEEEEE 1px solid;
        color: theme-color("primary");

        .svg-icon{
            &--logo-symbol {
                position: absolute;
                left: 0;
                top: 50%;
                width: rem(20px);
                transform: translateY(-50%);
            }

            &--close {
                width: rem(16px);
            }
        }

        .riepilogo-navigation {
            &__toggle {
                color: theme-color("primary");
            }
        }
    }

    &__footer {
        padding: $grid-gutter-width 0;
        text-align: center;
        background: #fff;
    }

    &__title {
        display: block;
        margin: rem(25px) 0;
        font-size: rem(18px);
        font-weight: 500;
        letter-spacing: rem(0.8px);
        text-align: center;
        color: theme-color("primary");
    }
}

.form-drag-drop {
    position: relative;
    height: 150px;
    border: theme-color("secondary") 1px solid;
    border-radius: 10px;
    cursor: pointer;

    &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        border: rgba(theme-color("primary"), 0.1) 1px dashed;
        border-radius: 10px;
        transform: translate(-50%, -50%);
    }

    &__title {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: block;
        padding-bottom: rem(5px);
        font-size: rem(15px);
        font-weight: 500;
        letter-spacing: rem(0.5px);
        color: theme-color("secondary");

        &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background: theme-color("secondary");
        }
    }

    &__text {
        position: absolute;
        left: 50%;
        bottom: rem(20px);
        width: 100%;
        margin: 0 0 rem(5px);
        font-size: rem(11px);
        font-weight: 500;
        letter-spacing: rem(1px);
        text-align: center;
        text-transform: uppercase;
        color: #CBCBCB;
        transform: translateX(-50%);
    }
}

.bottom-fields {
    &__fake-label {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: rem(10px);
        font-size: rem(14px);
    }

    &__separator {
        padding: 0;

        > span {
            display: block;
            width: 1px;
            height: rem(60px);
            background: #979797;
        }
    }

    .form-radio {
        margin-bottom: 0;
    }
}

@include media-breakpoint-up(lg) {
    .bottom-fields {
        &__fake-label {
            margin-bottom: 0;
            font-size: rem(16px);
        }

        .form-radio__label {
            .text {
                padding: rem(15px);
            }
        }

        .form-input {
            margin-bottom: 0;
        }
    }
}

.accordion {
    > .card {
        overflow: initial;
    }

    .card {
        border-top: #EFEFEF 1px solid;
        border-right: none;
        border-bottom: #EFEFEF 1px solid;
        border-left: none;
        border-radius: 0;

        .card-header {
            position: relative;
            padding: rem(20px) rem(40px);
            font-size: rem(15px);
            font-weight: 600;
            letter-spacing: 0.7px;
            background: none;
            border: none;
            color: theme-color("primary");
            cursor: pointer;

            &::after {
                content: "";
                position: absolute;
                right: rem(40px);
                top: 50%;
                display: block;
                width: rem(23px);
                height: rem(23px);
                background: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='23' height='23' viewBox='0 0 23 23'><g id='mockup' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g id='004V2-BTB-&gt;-TABLET-&gt;-cliente' transform='translate(-844.000000, -206.000000)' fill='#{$primary}' fill-rule='nonzero'><g id='Group-4' transform='translate(117.000000, 186.000000)'><g id='Group-2' transform='translate(727.000000, 20.500000)'><polygon id='Line-3' points='12 0 12 22 11 22 11 0'></polygon><polygon id='Line-3' transform='translate(11.500000, 11.000000) rotate(-90.000000) translate(-11.500000, -11.000000) ' points='12 0 12 22 11 22 11 0'></polygon></g></g></g></g></svg>")) center center no-repeat;
                background-size: contain;
                transform: translateY(-50%);
            }
        }

        .card-body {
            padding: rem(20px) rem(40px) rem(35px) rem(40px);
        }

        &.show {
            .card-header {
                &::after {
                    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='23' height='2' viewBox='0 0 23 2'><g id='mockup' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g id='004V2-BTB-&gt;-TABLET-&gt;-cliente' transform='translate(-844.000000, -288.000000)' fill='#{$primary}' fill-rule='nonzero'><g id='Group-4' transform='translate(117.000000, 186.000000)'><g id='Group-2' transform='translate(727.000000, 102.000000)'><polygon id='Line-3' transform='translate(11.500000, 1.000000) rotate(-90.000000) translate(-11.500000, -1.000000) ' points='12 -10 12 12 11 12 11 -10'></polygon></g></g></g></g></svg>"));
                }
            }
        }

        &.valid {
            .card-header {
                color: theme-color("secondary");

                &::after {
                    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='23' height='23' viewBox='0 0 23 23'><g id='mockup' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g id='004V2-BTB-&gt;-TABLET-&gt;-cliente' transform='translate(-844.000000, -206.000000)' fill='#{$secondary}' fill-rule='nonzero'><g id='Group-4' transform='translate(117.000000, 186.000000)'><g id='Group-2' transform='translate(727.000000, 20.500000)'><polygon id='Line-3' points='12 0 12 22 11 22 11 0'></polygon><polygon id='Line-3' transform='translate(11.500000, 11.000000) rotate(-90.000000) translate(-11.500000, -11.000000) ' points='12 0 12 22 11 22 11 0'></polygon></g></g></g></g></svg>"));
                }

                span {
                    position: relative;

                    &::after {
                        content: "";
                        position: absolute;
                        left: calc(100% + 10px);
                        top: 50%;
                        display: block;
                        width: rem(28px);
                        height: rem(30px);
                        background: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='28' height='30' viewBox='0 0 28 30'><g id='mockup' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g id='004V2-BTB-&gt;-TABLET-&gt;-cliente' transform='translate(-285.000000, -203.000000)' fill-rule='nonzero'><g id='Group-4' transform='translate(117.000000, 186.000000)'><g id='Group-3' transform='translate(168.000000, 17.500000)'><g id='icon-/-complete' transform='translate(0.000000, 0.700000)'><path d='M13.9472656,0 C21.6501277,0 27.8945312,6.24440352 27.8945312,13.9472656 C27.8945312,21.6501277 21.6501277,27.8945312 13.9472656,27.8945312 C6.24440352,27.8945312 0,21.6501277 0,13.9472656 C0,6.24440352 6.24440352,0 13.9472656,0 Z M13.9472656,1.5 C7.07283064,1.5 1.5,7.07283064 1.5,13.9472656 C1.5,20.8217006 7.07283064,26.3945312 13.9472656,26.3945312 C20.8217006,26.3945312 26.3945312,20.8217006 26.3945312,13.9472656 C26.3945312,7.07283064 20.8217006,1.5 13.9472656,1.5 Z' id='Oval' fill='#{$secondary}'></path><g id='icon-/-check_2' transform='translate(8.487266, 10.807266)' fill='#0076FF'><g id='icon-/-check'><path d='M4.74473931,-0.229325923 C5.12950201,-0.644947674 5.75947915,-0.697864277 6.20588055,-0.371543601 L6.30544234,-0.289499282 L8.36257775,1.61489808 C8.66653771,1.89628965 8.68483302,2.37081082 8.40344145,2.67477079 C8.14763093,2.95109803 7.73220917,2.99133877 7.43042246,2.78495793 L7.34356874,2.71563449 L5.601,1.102 L0.589385263,7.47081571 C0.356514042,7.76673252 -0.054353703,7.84009637 -0.371691722,7.6585341 L-0.463815709,7.59638526 C-0.759732521,7.36351404 -0.833096366,6.9526463 -0.651534099,6.63530828 L-0.589385263,6.54318429 L4.72286093,-0.204919565 C4.7282806,-0.211063788 4.73486408,-0.218368642 4.74473931,-0.229325923 Z' id='Path' transform='translate(3.926523, 3.586781) rotate(-180.000000) translate(-3.926523, -3.586781) '></path></g></g></g></g></g></g></g></svg>")) center center no-repeat;
                        background-size: contain;
                        transform: translateY(-50%);
                    }
                }
            }

            &.show {
                .card-header {
                    &::after {
                        background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='23' height='2' viewBox='0 0 23 2'><g id='mockup' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g id='004V2-BTB-&gt;-TABLET-&gt;-cliente' transform='translate(-844.000000, -288.000000)' fill='#{$secondary}' fill-rule='nonzero'><g id='Group-4' transform='translate(117.000000, 186.000000)'><g id='Group-2' transform='translate(727.000000, 102.000000)'><polygon id='Line-3' transform='translate(11.500000, 1.000000) rotate(-90.000000) translate(-11.500000, -1.000000) ' points='12 -10 12 12 11 12 11 -10'></polygon></g></g></g></g></svg>"));
                    }
                }
            }
        }
    }
}

body.richiedi-preventivo {
    .configurator__footer {
        bottom: auto;
        top: rem(20px);

        .btn.btn-link {
            color: $body-color;
        }
    }
}

.richiedi-preventivo {
    &__top {
        padding-top: rem(30px);
        padding-bottom: rem(30px);
        text-align: center;

        .svg-icon {
            &--logo {
                width: rem(115px);
                margin-bottom: rem(30px);
                color: theme-color("primary");
            }
        }

        p {
            font-size: rem(18px);
            letter-spacing: rem(0.8px);
        }
    }

    &__form {
        .form-group {
            margin-bottom: rem(25px);

            label {
                font-size: rem(14px);
                font-weight: 500;
            }

            &.required {
                label {
                    &::after {
                        content: " *";
                    }
                }
            }
        }

        .custom-control {
            margin-bottom: rem(35px);

            &-label {
                font-size: rem(13px);
                font-weight: 300;
                text-align: left;
                color: #24262C
            }
        }

        &.displayErrors {
            .form-control,
            .DateInput_input {
                &:invalid {
                    border-color: theme-color("danger");
                }
            }

            .custom-control {
                .custom-control-input {
                    &:invalid ~ .custom-control-label::before {
                        border-color: theme-color("danger");
                    }
                }
            }
        }
    }

    &__response {
        margin-top: rem(45px);
    }
}

@include media-breakpoint-up(lg) {
    .richiedi-preventivo {
        &__top {
            .svg-icon {
                &--logo {
                    margin-bottom: rem(60px);
                }
            }

            p {
                font-size: rem(25px);
            }
        }
    }
}

.riepilogo {
    &__header {
        &--mobile {
            position: relative;
            padding: rem(25px) $grid-gutter-width/2;
            margin: 0 -#{$grid-gutter-width/2};
            text-align: right;
            background: theme-color("primary");
            border-bottom: #fff 1px solid;
            color: #fff;

            .svg-icon{
                &--logo-symbol-monocolor {
                    position: absolute;
                    left: $grid-gutter-width/2;
                    top: 50%;
                    width: rem(20px);
                    transform: translateY(-50%);
                }
            }
        }
    }

    &__content {
        .ripilogo_preferenze {
            padding: rem(25px) $grid-gutter-width/2;
            margin: 0 -#{$grid-gutter-width/2} rem(45px) -#{$grid-gutter-width/2};
            text-align: center;
            background: theme-color("primary");
            border-bottom-left-radius: rem(40px);
            border-bottom-right-radius: rem(40px);
            color: #fff;

            h1 {
                margin: 0;
                font-size: rem(25px);
                font-weight: 600;
                letter-spacing: rem(1.1px);
            }
        }

        .ripilogo__preventivo {
            margin-bottom: rem(45px);

            h2 {
                margin: 0 0 rem(10px) 0;
                font-size: rem(23px);
                font-weight: 600;
                letter-spacing: rem(0.7px);
                color: theme-color("secondary");
            }

            .btn {
                margin-top: rem(20px);
            }
        }
    }

    &__preview {
        margin: rem(30px) 0 rem(45px) 0;
    }
}

@include media-breakpoint-up(md) {
    .riepilogo {
        &__header {
            &--desktop {
                padding: rem(35px) 0 rem(70px) 0;
                color: theme-color("primary");

                .svg-icon{
                    &--logo {
                        width: rem(115px);
                    }
                }
            }
        }

        &__content {
            .ripilogo_preferenze {
                padding: 0;
                margin: 0;
                text-align: center;
                background: transparent;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                color: $body-color;
    
                h1 {
                    margin-bottom: rem(15px);
                    color: theme-color("primary");
                }

                p {
                    font-size: rem(18px);
                }

                .navigation-nav {
                    margin: rem(70px) 0 rem(150px) 0;
                    text-align: left;

                    &__item {
                        padding-top: rem(20px);
                        padding-right: rem(45px);
                        padding-left: rem(60px);
                        margin-top: rem(20px);
                        border-top: #eee 1px solid;
                        
                        &::before {
                            left: rem(45px);
                            top: rem(25px);
                        }
                    }
                }
            }
    
            // .ripilogo__preventivo {
                // position: fixed;
                // right: 0;
                // top: 50%;
                // width: 50%; //33.33333%;
                // padding: rem(75px);
                // background: #fff;
                // border-top-left-radius: rem(50px);
                // border-bottom-left-radius: rem(50px);
                // transform: translateY(-50%);
    
                // h2 {
                //     margin-bottom: rem(20px);
                //     font-size: rem(30px);
                // }
    
                // .btn {
                //     margin-top: rem(20px);
                // }
            // }
        }
    }
}

@include media-breakpoint-up(xl) {
    .riepilogo {
        &__content {
            .ripilogo__preventivo {
                width: 33.33333%;
            }
        }
    }
}

.logo-confirm {
    position: relative;
    
    &__logo {
        position: absolute;
        left: 6.5%;
        top: 34%;
        z-index: 20;
        width: 86.5%;
        height: 36%;
        transform: perspective(1112px) rotateX(-28deg);

        img {
            position: absolute;
        }
    }

    &__background {
        padding-top: (446px / 1250px) * 100%; // ($height / $width) * 100%

        .img-fluid {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
}

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1360px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1300px
);

$grid-columns:                24;
$grid-gutter-width:           24px;

$primary: 					#0A1F8F;
$secondary: 				#0076FE;
$tertiary: 		      		#E5E5E5;

$font-family-sans-serif: 'Montserrat', sans-serif;
$font-size-context: 16px;

$body-color: #24262C;

$btn-padding-x-lg:      45px;
$btn-padding-y-lg:      20px;
$btn-font-size:         14px;
$btn-font-size-lg:      14px;
$btn-line-height-lg:    1;
$btn-font-weight:       500;
$btn-border-radius:     100px;
$btn-border-radius-sm:  $btn-border-radius;
$btn-border-radius-lg:  $btn-border-radius;

$modal-header-border-color: transparent;
$modal-footer-border-color: transparent;